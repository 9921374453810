<template>
  <section id="landing" :class="`detect_deviece_${deviceType} state_${deviceType}`">
    <NavBar title="News Details" @go-back="goback" />

    <section v-if="hasNews" id="el" class="content">
      <h1 class="news-title">
        {{ newsObj?.title }}
      </h1>
      <div class="news-info-row">
        <div>
          {{ newsObj?.channel }}
        </div>
        <div>
          {{ newsObj?.publishedTime? $global.timeAgo($global.dateStringToTimestamp(newsObj?.publishedTime)):'recently' }}
        </div>
      </div>

      <img :src="newsObj?.poster" class="figure_poster" :alt="newsObj?.poster">

      <div class="adsItem">
        <div id="pos_detail" />
      </div>
      <template v-if="newsObj?.content && newsObj?.content.length > 0 ">
        <section v-for="(context, i) in newsObj?.content" :key="i" class="content-row">
          <h2 v-if="context.subTitle" v-html="context.subTitle" />
          <img v-show="i > 0" v-if="context.img" v-lazy="context.img" class="figure"
            :alt="context.subTitle">
          <div v-html="context.content" />
        </section>
      </template>
    </section>

    <section v-if="!hasNews && requirDataAlready" class="no-data-row">
      <img src="@/assets/news/nodata.png" alt="">
      <div class="no-data">
        Uh-oh. What you were looking for got away from you
      </div>
    </section>

    <div v-if="!requirDataAlready" class="loading">
      <img src="@/assets/news/loading.png" alt="">
    </div>
  </section>
</template>

<script>
import '@/css/module/landing/theme_1.scss';
import NavBar from '@/pages/components/NavBar.vue';

export default {
  name: 'LandingMain',
  components: {
    NavBar
  },
  data () {
    return {
      adsense: null,

      themeType: 1,
      newsId: '',
      newsObj: {},
      requirDataAlready: false,
      hasNews: false,

    };
  },
  computed: {
    themeConfig () {
      return this.$store.state.themeConfig;
    },
    deviceType () {
      return this.$store.state.deviceType;
    },
  },
  async activated () {
    this.themeType = this.$route.query.thme || 1;
    const newNewsId = Number(this.$route.query.id);
    if (this.newsId !== newNewsId) {
      this.newsId = newNewsId;
      console.log('News ID updated:', this.newsId);
      try {
        await this.getNews();
        this.loadBeedsadScript();  // 重新加载广告脚本
      } catch (error) {
        console.error('An error occurred in getList:', error);
      }
    } else {
      console.log('News ID not changed:', this.newsId);
    }

    this.$eventrackFb('show_details_h5', 'expose', {
      website: this.website,
      id: this.newsId,
      pve_cur: '/h5page/news/details/',
    });
  },
  beforeMount () {
    this.adsense = this.$store.state.adsenseConfig;
  },
  async mounted () {
    console.log('mounted-------------------');
  },
  deactivated () {
    console.log('-----------Component deactivated');
  },
  beforeDestroy () {
    console.log('beforeDestroy-------------------');
    window.adsTag.stopAds(document.querySelector('#pos_4'));
    window.adsTag.stopAds(document.querySelector('#pos_7'));
  },
  methods: {
    loadBeedsadScript () {
      window.adsTag.cmd.push(() => {
        window.adsTag.renderAds(document.querySelector('#pos_detail'), 336, 280, this.adsense.bees_detail_pos_1);
      });
    },
    goback () {
      this.$router.go(-1);
    },
    async getNews (id) {
      this.hasNews = false;
      const timestamp = new Date().getTime();
      try {
        const res = await this.$global.server.getNews({
          id: id ? id : this.newsId,
          _: timestamp  // 添加时间戳作为查询参数
        });

        this.requirDataAlready = true;

        if (res.code != 200) {
          console.log('获取失败');
          return;
        }

        this.hasNews = true;
        this.newsObj = {
          title: res.data.title,
          channel: res.data.channel,
          poster: res.data.articleDetails?.[0]?.img,
          publishedTime: res.data.published,
          content: res.data.articleDetails
        };

      } catch (err) {
        this.hasNews = false;
        this.requirDataAlready = true;
        console.error('An error occurred while getting news:', err);
      }
    },
  },

};
</script>
